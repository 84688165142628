body {
  font-size: 16px;
}

.pointer {
  cursor: pointer;
}
.main-body.csession {
  height: 100vh;
  background-color: var(--dark-blue);
}
.main-body.csession.dark-interaction-part {
  background-color: var(--dark-blue);
}
.main-body.csession.light-interaction-part {
  /*background-color: var(--lighter-blue);*/
  background-color: var(--white);

}
a:link, a:visited, a:hover {
  color: var(--blue);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  /*text-shadow: 1px 1px 2px var(--lighter-blue);*/
}
.mobile {
  display:none;
}
:root {
  --white: #fff;
  --yellow: yellow;
  --grey: grey;
  --grey-link: #f4f4f4;
  --red: #ea3e23;
  --red-link: #d9376e;
  --green: #54b788;
  --black: #2D334A;
  --black-link: #16163f;
  --dark-blue: #192f46;
  --bright-blue:blue;
  --blue: #48698a;
  --lighter-blue: #cbebec;
  --light-blue: #bfd5ec;
}
@media (max-width: 768px) {
  .mobile {
    display: inline-block;
  }
}