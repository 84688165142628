.header {
    background-color: var(--green);

    /*padding: 11px 0 8px 14px;*/
    /*display: flex;*/
}
.header-logo img {
    width: 140px;
    height: 26px;
}
.header-logo {
    display: inline-block;
}
.header.session-window {
    background-color: transparent;
    border-bottom: 3px solid var(--black);
}

.header a, .header a:visited, .header a:hover {
    color: var(--black-link);
    font-size: 1em;
    text-decoration: none;
}
.header a:hover, .dropdown-button:hover {
    opacity:0.6;
}
.header .header-logo a:hover {
    opacity: 1;
}

.header .menu-top-row a, .header .menu-top-row a:visited, .header .menu-top-row a:hover, .menu-top-row .dropdown-button {
    color: var(--grey-link);
    font-size: 1em;
    text-decoration: none;
}


.menu-top-row {
    display: flex;
    justify-content: flex-end; /* Aligns the top menu items to the right */
    padding: 5px 20px; /* Adds some padding for spacing */
    background:#54b788;
}

.menu-top-row-item {
    margin-left: 20px; /* Adds spacing between the top menu items */
}

.second-menu-row {
    display: flex;
    align-items: center; /* Vertically centers the items in the row */
    padding: 15px 20px 10px 20px; /* Adds some padding for spacing */
    background:#aadcc4;
    border-top: 1px solid #141f2b;
    box-shadow: 0 5px 10px #e2e2e2;
}

.header-logo {
    margin-right: 100px; /* Adds a 40px margin after the logo */
}

.menu-second-row-item {
    margin-left: 20px; /* Adds spacing between the second-row menu items */
}

.login-info {
    margin-left: auto; /* Pushes the login-info to the right side of the row */
}

.hamburger-menu {
    cursor: pointer;
    display: none;
    position: relative;
    top: -2px;
}
.session-window .menu-top-row {
    display: none
}
.session-window .menu-second-row-item{
    display: none;
}
.main-page-menu-item {
    margin-right:auto;
    margin-left:20px;
}
.dropdown-button {
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .dropdown-button {
        margin-right: 10px;
        font-size: 1em;
    }
    .hamburger-menu {
        display: inline-block;
        margin-left: 10px;
    }
    .login-info {}
    .menu-top-row {
        display: none;
    }
    .menu-second-row-item {
        display: none;
    }
    .header-logo {
        margin-right: 5px
    }
    .session-window .hamburger-menu {
        display: none;
    }
}