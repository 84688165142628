.profile-phone {
    margin-top: 15px;
    margin-bottom: 15px;
}
.profile-phone .PhoneInputInput {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    /*border: 0;*/
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;

    border-radius: 4px;
    border:1px solid #c4c4c4;
}
.profile-delete {
    color: var(--red);
    cursor: pointer;
    text-align: right;
}