.therapy-form .MuiFormControl-root {
    min-width: 48.5%;
}
.therapy-form .top-row-3-elements .MuiFormControl-root {
    min-width: 32.5%;
}
.reset-sets-counter {
    /*margin-right: 10px;*/
    margin-top: 4px;
    text-align: left;
    color: var(--green);
    font-size: 0.8em;
    cursor: pointer;
}