.client-connected {
    font-size:1.2em;
    margin:20px 20px;
    color: var(--green);
}
.client-not-connected {
    font-size:1.2em;
    margin:20px 20px;
    color: var(--red);
    background-color: var(--yellow);
}
.preview-show-hide-controls {
    margin-right: 10px;
    margin-top: -18px;
    text-align: right;
    color: var(--green);
    font-size: 0.9em;
}