
body .ms_lms_loader {
    border-color: #3fa385 #3fa385 transparent transparent;
}
.ms_lms_loader {
    width: 108px;
    height: 108px;
    position: fixed;
    top: calc(50vh - 54px);
    border-radius: 50%;
    display: inline-block;
    border: 3px solid;
    border-color: #17d292 #17d292 transparent transparent;
    box-sizing: border-box;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    left: calc(50vw - 54px);
}
body .ms_lms_loader::after, .ms_lms_loader::before {
    border-color: transparent transparent #001525 #001525;
}
.ms_lms_loader::after, .ms_lms_loader::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #385bce #385bce;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-sizing: border-box;
    -webkit-animation: rotationBack .5s linear infinite;
    animation: rotationBack .5s linear infinite;
    -webkit-transform-origin: center center;
    transform-origin: center center;

}


@keyframes rotation {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@keyframes rotationBack {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}