.interaction-part {
    color: var(--lighter-blue);
}
.interaction-part .session-status {
    /*margin-left:10px;*/
    text-align: center;
}
.waiting-therapist {
    /*color: var(--white);*/
    text-align: center;

    /*font-size: 1.2em;*/
    /*margin: 20px;*/
}
.waiting-therapist span {
    color: var(--red);
    background-color: var(--yellow);
}
/*.light-interaction-part .waiting-therapist {*/
/*    color: var(--dark-blue);*/
/*}*/
.dark-interaction-part .waiting-therapist, .dark-interaction-part .session-status {
    color: var(--white);
}
.session-status {
    text-align: center;
    position: absolute;
    top:20px;
    width:100%;
}
