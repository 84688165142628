.emdr-bar-container {
    width: 100%;
    background-color: var(--dark-blue);
    height: 200px;
}
.emdr-bar {
    background-color: var(--dark-blue);
    border: 3px solid var(--light-blue);
    height: 100px;
    margin: 0 55px;
    position: relative;
    top: 50px;

}
.emdr-light {
    height: 90px;
    width: 90px;
    top: 5px;
    background-color: var(--light-blue);
    position: relative;
}
.emdr-bar-inner {
    width: calc(100% - 90px);
}
.light-shape-round .emdr-light {
    border-radius: 45px;
}
.light-shape-round .emdr-bar {
    border-radius: 50px;
}
.emdr-title {
    font-size:2em;
    margin:10px 20px;
    color: #e2e2e2;
    position: absolute;
}
.emdr-bar-container.light-size-1 {
    zoom: 0.7;
}
.emdr-bar-container.light-size-2 {
    zoom: 0.85;
}
.emdr-bar-container.light-size-3 {
    zoom: 1;
}
.emdr-bar-container.light-size-4 {
    zoom: 1.15;
}
.emdr-bar-container.light-size-5 {
    zoom: 1.3;
}
.emdr-bar-container.preview {
    zoom:0.3;
}
.emdr-bar-container.preview.light-size-1 {
    zoom:0.2;
}
.emdr-bar-container.preview.light-size-2 {
    zoom:0.25;
}
.emdr-bar-container.preview.light-size-3 {
    zoom:0.3;
}
.emdr-bar-container.preview.light-size-4 {
    zoom:0.35;
}
.emdr-bar-container.preview.light-size-5 {
    zoom:0.4;
}
/*.emdr-bar-container.colour-preset-light {*/
/*    filter:invert(1);*/
/*}*/
.emdr-bar-container.colour-preset-light.emdr-bar-container {
    /*background-color: var(--lighter-blue);*/
    background-color: var(--white);
}
.colour-preset-light .emdr-bar {
    /*background-color: var(--lighter-blue);*/
    background-color: var(--white);
    border: 3px solid var(--red);
}
.colour-preset-light .emdr-light {
    background-color: var(--red);
}